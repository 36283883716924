  <template>
  <div>
    <div class="application">
      <menu-top :account="account" />
      <div class="line" style="text-align: center">
        <h1 style="text-transform: capitalize">
          Bienvenue {{ account.fullname }}
        </h1>
        <h3>
          Gérer votre compte en toute simplicité : <br />consulter vos factures,
          modifier vos informations personnelles et effectuer vos réservations
          de vélos.
        </h3>
      </div>
      <div class="line-m" style="text-align: center; margin-top: 30px">
        <ul class="board-menu" style="width:100%">
          <li style="width:50%">
            <div
              class="board-item"
              @click="navigation('reservation')"
            >
              <!-- v-if="
                account.fin_contrats < datenow &&
                (account.nb_contrats === undefined || account.nb_contrats < maxContrat)
              " -->
              <div class="board-icon">
                <i class="fa fa-plus"></i>
              </div>
              <div class="board-text">
                <h2>Réserver un vélo</h2>
                <label style="color: var(--C2)"
                  >Effectuer une réservation de vélos et réaliser le paiement en
                  ligne</label
                >
              </div>
            </div>
            <!-- <div v-else class="board-item">
              <div class="board-icon">
                <i class="fa fa-plus"></i>
              </div>
              <div class="board-text">
                <h2>Réserver un vélo</h2>
                <label style="color: red"
                  >Vous avez déjà atteint le maximum de 1 contrats de
                  locations</label
                >
              </div>
            </div> -->
            <div v-if="account.fin_contrats >= datenow" class="board-item">
              <div class="board-icon">
                <i class="fa fa-plus"></i>
              </div>
              <div class="board-text">
                <h2>Réserver un vélo</h2>
                <label style="color: red"
                  >Vous avez déjà un contrat de location en cours.</label
                >
              </div>
            </div>
          </li>
          <li style="width:50%">
            <div class="board-item" @click="navigation('account')">
              <div class="board-icon">
                <i class="fa fa-user"></i>
              </div>
              <div class="board-text">
                <h2>Votre compte</h2>
                <label style="color: var(--C2)"
                  >Modifier vos informations personnelles et envoyer vos
                  justificatifs</label
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="line-m"
        style="text-align: center; margin-top: 30px; padding-bottom: 30px"
      >
        <ul class="board-menu" style="width:100%">
          <li style="width:50%">
            <div class="board-item" @click="navigation('rentals')">
              <div class="board-icon">
                <i class="fa fa-file-invoice"></i>
              </div>
              <div class="board-text">
                <h2>Vos contrats</h2>
                <label style="color: var(--C2)"
                  >Historique de vos contrats de locations de vélos et
                  accessoires</label
                >
              </div>
            </div>
          </li>
          <li style="width:50%">
            <div class="board-item" @click="navigation('invoices')">
              <div class="board-icon">
                <i class="fa fa-file-invoice"></i>
              </div>
              <div class="board-text">
                <h2>Vos factures</h2>
                <label style="color: var(--C2)"
                  >Historique de vos factures de locations de vélos et
                  accessoires</label
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <loadingform v-if="loading"></loadingform>
  </div>
</template>

<script>
import MenuTop from "@/components/MenuTop";
import engine from "@/core/data/DataAPI";
import loadingform from "@/core/controls/LoadingForm";

export default {
  components: {
    loadingform,
    MenuTop,
  },
  data() {
    return {
      maxContrat:100,
      account: {},
      loading: false,
      datenow: "",
    };
  },
  created: function () {
    var tmain = this;
    tmain.loading = true;
    tmain.datenow = tmain.$moment().format("YYYY-MM-DD");
    engine.Account_Info(function (pkg) {
      if (pkg.code != 0) {
        tmain.$router.push("/");
      } else {
        tmain.account = pkg.data[0];
      }
      tmain.loading = false;
    });
  },
  methods: {
    navigation: function (app) {
      var tmain = this;
      tmain.$router.push("/" + app);
    },
  },
};
</script>
<style>
</style>
