<template>
  <div>
    <div class="application">
      <menu-top :account="account" />
      <div class="line" style="text-align: center">
        <h1>Vos factures</h1>
        <h3>Historique de vos factures de locations</h3>
      </div>
      <div class="line-m" style="margin-top: 40px">
        <table class="ftable">
          <tr>
            <th>Numéro de facture</th>
            <th>Date de facture</th>
            <th>Etat</th>
            <th>Numéro de contrat</th>
            <th>Montant TTC</th>
            <th>Télécharger</th>
          </tr>
          <tr v-for="invoice in list" :key="invoice.id">
            <td>{{ invoice.invoice_number }}</td>
            <td>{{ invoice.invoice_date | moment("DD/MM/YYYY") }}</td>
            <td>{{ invoice.state }}</td>
            <td>{{ invoice.rental_number }}</td>
            <td style="text-align: right">{{ invoice.amount | money }}</td>
            <td
              style="color: var(--BUTTON); text-align: center; cursor: pointer"
              @click="donwloadInvoice(invoice)"
            >
              <i class="fa fa-upload"></i>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <loadingform v-if="loading"></loadingform>
  </div>
</template>

<script>
import engine from "@/core/data/DataAPI";
import MenuTop from "@/components/MenuTop";
import loadingform from "@/core/controls/LoadingForm";

export default {
  components: {
    MenuTop,
    loadingform,
  },
  data() {
    return {
      loading: false,
      account: {},
      vdate: "",
      list: [],
    };
  },
  created: function () {
    var tmain = this;
    this.$moment.locale("fr");
    tmain.loading = true;
    engine.Account_Info(function (pkg) {
      if (pkg.code != 0) {
        tmain.loading = false;
        tmain.$router.push("/");
      } else {
        tmain.account = pkg.data[0];
        engine.Invoices_List(function (pkginvoices) {
          tmain.loading = false;
          if (pkginvoices.code == 0) {
            tmain.list = pkginvoices.data;
          }
        });
      }
    });
  },
  methods: {
    donwloadInvoice: function (invoice) {
      engine.File_Invoice_Download(invoice.invoice_number, function (pkgfile) {
        var extname = ".pdf";
        var fileURL = window.URL.createObjectURL(new Blob([pkgfile.data]));
        var download_link = document.createElement("a");
        download_link.href = fileURL;
        download_link.download = invoice.invoice_number + extname;
        download_link.target = "_blank";
        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);
      });
    },
    navigation: function (app) {
      var tmain = this;
      tmain.$router.push("/" + app);
    },
  },
};
</script>
<style>
</style>
