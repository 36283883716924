<template>
  <div class="application">
    <div v-if="viewdiv == 'create'" class="divlogin">
      <img src="media/logo.png" alt="" style="height: 40px; margin-top: 40px" />
      <h2 style="margin: 0px; margin-top: 5px; margin-bottom: 7px">Création</h2>
      <h3 style="margin: 0px">Créer votre compte VIA Vélo</h3>

      <select
        v-model="civilite"
        :class="{ 'finput-form': true }"
        @change="account.civilite = civilite"
      >
        <option value="" disabled selected>Civilité</option>
        <option value="M">Homme</option>
        <option value="Mme">Femme</option></select
      ><br /><br />

      <input
        type="text"
        :class="{ 'finput-form': true, 'finput-error': msglastname != '' }"
        v-model="account.lastname"
        placeholder="Nom"
      />
      <label
        v-if="msglastname != ''"
        style="
          color: red;
          width: 400px;
          margin-left: 40px;
          font-size: 14px;
          float: left;
          text-align: left;
        "
        ><i class="fa fa-info-circle"></i> {{ msglastname }}</label
      ><br /><br />
      <input
        type="text"
        :class="{ 'finput-form': true, 'finput-error': msgfirstname != '' }"
        v-model="account.firstname"
        placeholder="Prénom"
      />
      <label
        v-if="msgfirstname != ''"
        style="
          color: red;
          width: 400px;
          margin-left: 40px;
          font-size: 14px;
          float: left;
          text-align: left;
        "
        ><i class="fa fa-info-circle"></i> {{ msgfirstname }}</label
      ><br /><br />
      <input
        type="text"
        :class="{ 'finput-form': true, 'finput-error': msgemail != '' }"
        v-model="account.email"
        placeholder="Adresse e-mail"
      />
      <label
        v-if="msgemail != ''"
        style="
          color: red;
          width: 400px;
          margin-left: 40px;
          font-size: 14px;
          float: left;
          text-align: left;
        "
        ><i class="fa fa-info-circle"></i> {{ msgemail }}</label
      ><br /><br />
      <input
        type="text"
        :class="{ 'finput-form': true, 'finput-error': msgaddress != '' }"
        v-model="account.address"
        placeholder="Adresse (rue, chemin,...)"
      />
      <label
        v-if="msgaddress != ''"
        style="
          color: red;
          width: 400px;
          margin-left: 40px;
          font-size: 14px;
          float: left;
          text-align: left;
        "
        ><i class="fa fa-info-circle"></i> {{ msgaddress }}</label
      ><br /><br />
      <input
        type="text"
        :class="{ 'finput-form': true, 'finput-error': msgzipcode != '' }"
        v-model="account.zipcode"
        placeholder="Code postal"
      />
      <label
        v-if="msgzipcode != ''"
        style="
          color: red;
          width: 400px;
          margin-left: 40px;
          font-size: 14px;
          float: left;
          text-align: left;
        "
        ><i class="fa fa-info-circle"></i> {{ msgzipcode }}</label
      ><br /><br />
      <input
        type="text"
        :class="{ 'finput-form': true, 'finput-error': msgcity != '' }"
        v-model="account.city"
        placeholder="Ville"
      />
      <label
        v-if="msgcity != ''"
        style="
          color: red;
          width: 400px;
          margin-left: 40px;
          font-size: 14px;
          float: left;
          text-align: left;
        "
        ><i class="fa fa-info-circle"></i> {{ msgcity }}</label
      ><br /><br />
      <input
        type="text"
        :class="{ 'finput-form': true, 'finput-error': msgmobile != '' }"
        v-model="account.mobile"
        placeholder="Téléphone mobile"
      />
      <label
        v-if="msgmobile != ''"
        style="
          color: red;
          width: 400px;
          margin-left: 40px;
          font-size: 14px;
          float: left;
          text-align: left;
        "
        ><i class="fa fa-info-circle"></i> {{ msgmobile }}</label
      ><br /><br />
      <!--      <p style="text-align: left;margin-left: 40px;margin-bottom: 0px;color: var(--C2);">Date de naissance</p> -->
      <!--      <input type="date" :class="{'finput-form':true,'finput-error':(msgbirthdate!='')}" style="margin-top:0px;" v-model="account.birthdate" data-placeholder="Date de naissance"> -->
      <div
        class="form-input"
        style="margin: 0px; padding: 0px; margin-left: 40px; width: 400px"
      >
        <datepicker
          style="margin-top: 20px; padding-left: 0px; border: none"
          :language="lgdate"
          format="dd/MM/yyyy"
          :class="{ 'finput-form': true, 'finput-error': msgbirthdate != '' }"
          v-model="account.birthdate"
          placeholder="Date de naissance"
        ></datepicker>
      </div>
      <label
        v-if="msgbirthdate != ''"
        style="
          color: red;
          width: 400px;
          margin-left: 40px;
          font-size: 14px;
          float: left;
          text-align: left;
        "
        ><i class="fa fa-info-circle"></i> {{ msgbirthdate }}</label
      ><br /><br />
      <input
        type="password"
        :class="{ 'finput-form': true, 'finput-error': msgpwd != '' }"
        v-model="account.pwd"
        placeholder="Mot de passe"
      />
      <label
        v-if="msgpwd != ''"
        style="
          color: red;
          width: 400px;
          margin-left: 40px;
          font-size: 14px;
          float: left;
          text-align: left;
        "
        ><i class="fa fa-info-circle"></i> {{ msgpwd }}</label
      ><br /><br />
      <input
        type="password"
        :class="{ 'finput-form': true, 'finput-error': msgpwdconfirm != '' }"
        v-model="account.pwdconfirm"
        placeholder="Confirmer le mot de passe"
      />
      <label
        v-if="msgpwdconfirm != ''"
        style="
          color: red;
          width: 400px;
          margin-left: 40px;
          font-size: 14px;
          float: left;
          text-align: left;
        "
        ><i class="fa fa-info-circle"></i> {{ msgpwdconfirm }}</label
      ><br /><br />
      <label class="divloginpwd"
        ><i class="fa fa-info-circle"></i> Le mot de passe doit contenir au
        minimum 1 majuscule, 1 minuscule, 1 chiffre, 1 caractère spécial et
        avoir une longueur minimale de 8 caractères.</label
      >
      <table
        style="
          width: 410px;
          border-spacing: 0;
          padding: 0px;
          margin: 50px 0px 30px 40px;
        "
      >
        <tr>
          <td
            style="text-align: left; padding: 0px; margin: 0px; cell-spacing: 0"
            @click="btlogin"
          >
            <label class="flink">Se connecter avec un compte existant</label>
          </td>
          <td v-if="loading == false" style="text-align: right">
            <button @click="btlogincreate" class="fbutton">Suivant</button>
          </td>
          <td v-if="loading == true" style="text-align: right">
            <button class="fbutton">
              <i class="fas fa-circle-notch fa-spin"></i>
            </button>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="viewdiv == 'create_ok'" class="divlogin">
      <img src="media/logo.png" alt="" style="height: 40px; margin-top: 40px" />
      <h2 style="margin: 0px; margin-top: 5px; margin-bottom: 25px">
        Validation de votre adresse e-mail
      </h2>
      <h3 style="margin: 0px; cursor: pointer">
        Un e-mail vient de vous être envoyé à l'adresse {{ account.email
        }}<br />(cela peut prendre quelques minutes).<br />Si vous ne le trouvez
        pas, pensez à vérifier vos spams.<br /><b>{{ email }}</b
        ><br /><br />Veuillez suivre les instructions fournies<br />pour valider
        votre compte VIA Vélo.<br /><br />
      </h3>
      <button @click="btlogin" class="fbutton">Terminé</button>
    </div>
    <div v-if="viewdiv == 'valid'" class="divlogin">
      <img src="media/logo.png" alt="" style="height: 40px; margin-top: 40px" />
      <h2 style="margin: 0px; margin-top: 5px; margin-bottom: 25px">
        Validation de votre compte
      </h2>
      <h3 style="margin: 0px; cursor: pointer">
        Votre compte a été validé avec succès<br /><br />
      </h3>
      <button @click="btlogin" class="fbutton">Se connecter</button>
    </div>
  </div>
</template>

<script>
import engine from "@/core/data/DataAPI";
import datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";

export default {
  components: {
    datepicker,
  },
  data() {
    return {
      civilite: "",
      viewdiv: "",
      loading: false,
      account: {},
      lgdate: fr,
      msglastname: "",
      msgfirstname: "",
      msgemail: "",
      msgaddress: "",
      msgzipcode: "",
      msgcity: "",
      msgbirthdate: "",
      msgmobile: "",
      msgpwd: "",
      msgpwdconfirm: "",
    };
  },
  mounted: function () {
    this.$moment.locale("fr");
  },
  created: function () {
    var tmain = this;

    tmain.msglastname = "";
    tmain.msgfirstname = "";
    tmain.msgemail = "";
    tmain.msgaddress = "";
    tmain.msgzipcode = "";
    tmain.msgcity = "";
    tmain.msgbirthdate = "";
    tmain.msgmobile = "";
    tmain.msgpwd = "";
    tmain.msgpwdconfirm = "";
    tmain.account = {
      email: "",
      pwd: "",
      pwdconfirm: "",
      birthdate: "",
      firstname: "",
      lastname: "",
      address: "",
      zipcode: "",
      city: "",
      mobile: "",
    };
    if (JSON.stringify(tmain.$route.query) != "{}") {
      switch (tmain.$route.query.state) {
        case "valid":
          engine.Account_Create_Valid(
            tmain.$route.query.apikey,
            function (pkgv) {
              if (pkgv.code == 0) {
                tmain.viewdiv = "valid";
              } else {
                tmain.$router.push("/account/login");
              }
            }
          );
          break;
      }
    } else {
      tmain.viewdiv = "create";
    }
  },
  methods: {
    btlogin: function () {
      var tmain = this;
      tmain.$router.push("/account/login");
    },
    btlogincreate: async function () {
      var tmain = this;
      var has_error = false;
      tmain.loading = true;
      tmain.msglastname = "";
      tmain.msgfirstname = "";
      tmain.msgemail = "";
      tmain.msgaddress = "";
      tmain.msgzipcode = "";
      tmain.msgcity = "";
      tmain.msgbirthdate = "";
      tmain.msgmobile = "";
      tmain.msgpwd = "";
      tmain.msgpwdconfirm = "";
      if (tmain.account.lastname == "") {
        tmain.msglastname = "Le nom de famille est obligatoire";
        has_error = true;
      }
      if (tmain.account.firstname == "") {
        tmain.msgfirstname = "Le prénom est obligatoire";
        has_error = true;
      }
      if (tmain.account.email == "") {
        tmain.msgemail = "L'adresse email est obligatoire";
        has_error = true;
      }
      if (tmain.account.address == "") {
        tmain.msgaddress = "L'adresse postale est obligatoire";
        has_error = true;
      }
      if (tmain.account.zipcode == "") {
        tmain.msgzipcode = "Le code postal est obligatoire";
        has_error = true;
      }
      if (isNaN(tmain.account.zipcode)) {
        tmain.msgzipcode = "Le code postal doit être un nombre";
        has_error = true;
      }
      if (tmain.account.city == "") {
        tmain.msgcity = "La ville est obligatoire";
        has_error = true;
      }
      if (tmain.account.birthdate == "") {
        tmain.msgbirthdate = "La date de naissance est obligatoire";
        has_error = true;
      }
      if (
        tmain.$moment
          .duration(tmain.$moment().diff(tmain.account.birthdate))
          .asYears() < 18
      ) {
        tmain.msgbirthdate =
          "Vous devez avoir 18 ans ou + pour pouvoir créer un compte";
        has_error = true;
      }
      // if (tmain.account.mobile == "") {
      //   tmain.msgmobile = "Le téléphone mobile est obligatoire";
      //   has_error = true;
      // }
      var regExp =
        /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,}$/gm;
      if (!regExp.test(tmain.account.pwd)) {
        tmain.msgpwd =
          "Veuillez respecter les règles de saisie pour le mot de passe.";
        has_error = true;
      }
      var regExpMail =
        /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/gim;
      if (!regExpMail.test(tmain.account.email)) {
        tmain.msgemail = "L'adresse mail n'est pas valide.";
        has_error = true;
      }
      if (tmain.account.pwd != tmain.account.pwdconfirm) {
        tmain.msgpwdconfirm = "Les mots de passe sont différents";
        has_error = true;
      }
      if (tmain.account.email.indexOf(" ") >= 0) {
        tmain.msgemail =
          "Adresse invalide. Votre mail ne peut pas contenir d'espaces";
        has_error = true;
      }
      if (!has_error) {
        await this.$recaptchaLoaded();
        var token = await this.$recaptcha("login");
        engine.Recaptcha_Send(token, function (pkg) {
          if (pkg.code == 0) {
            engine.Account_Create(tmain.account, function (pkgs) {
              tmain.loading = false;
              if (pkgs.code == 0) {
                tmain.account.email.replace(/ /g, "").trim();
                tmain.viewdiv = "create_ok";
              } else {
                if (pkgs.code == -100) {
                  tmain.msgemail = "Cette adresse email est déjà référencée.";
                } else {
                  alert(
                    "Une erreur est survenue lors de la création du compte"
                  );
                }
              }
            });
          } else {
            tmain.loading = false;
          }
        });
      } else {
        tmain.loading = false;
      }
    },
  },
};
</script>
<style>
</style>
