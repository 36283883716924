<template>
  <div>
    <div class="application">
      <menu-top :account="account" />
      <div v-if="viewdiv == 'reservation'">
        <div class="line" style="text-align: center">
          <h1>Nouvelle réservation</h1>
          <h3>Effectuez votre réservation en ligne</h3>
        </div>
        <div class="line-a" style="margin-top: 40px; margin-bottom: 40px">
          <div v-if="step >= 1" class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Etape 1 / {{ maxstep }}</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                <br>
                Sélectionnez le type du vélo que vous souhaitez louer pour vous inscrire sur la liste d’attente. La
                Communauté de communes organise la distribution des VAE par vague et vous contactera pour convenir d’un
                rendez-vous dès qu’un VAE du modèle choisi sera disponible à la location. <br> Le retrait du vélo se fait
                uniquement sur rendez-vous, une fois que les pièces auront été vérifiées et validées, et le paiement de la
                totalité de la location effectué.
              </p>
              <!-- <br /> -->
              <!-- <p
                style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                  text-align: justify;
                "
              >
                Il est important de choisir un vélo à votre taille afin que
                l’expérience soit la plus agréable possible. Votre taille doit
                se trouver dans la fourchette de taille du vélo choisi. Par
                exemple : si je fais 165cm, je prends un vélo S 155-170cm.
              </p> -->
              <br />
              <!-- <p
                style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                  text-align: justify;
                "
              >
                Si vous souhaitez louer un siège bébé avec votre vélo, veillez à
                bien le sélectionner dans la liste (pas de surcoût, mais le
                siège bébé ne permet pas d’installer une sacoche en plus sur le
                porte bagage).
              </p>
              <br /> -->
              <!-- <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                  text-align: justify;
                ">
                Le retrait du vélo se fait uniquement sur rendez-vous, une fois
                que les pièces auront été vérifiées et validées et le paiement
                de la totalité de la location effectué. En cas de vélo
                indisponible, vous pouvez vous inscrire sur liste d’attente, en
                sélectionnant le modèle de vélo demandé ci-dessous.
              </p> -->
            </div>
            <ul class="family-bloc-text">
              <div align="center" class="account-bloc-title">
                VAE
                <div style="color: red; font-size: 13px; padding: 0px; margin: 0px" v-if="account.nb_contrats_vae > 0">
                  ( Un contrat VAE est déja en cours )
                </div>
              </div>
              <li v-for="material in list_materials_vae" :key="material.id"
                @click="validS1(material, account.nb_contrats_vae)" style="border: none">
                <div class="family-bloc-title">{{ material.titre1 }}</div>
                <div v-if="material.qty > 0">
                  <div class="family-bloc-icon">
                    <img :src="'./media/velo_' + material.id + '.jpg'"
                      :src-placeholder="'./media/velo_' + material.id + '.jpg'" alt=""
                      :class="{ 'border-bloc-selected': material.selected }" style="height: 100px" />
                    <!--<img :src="'./media/velo_'+material.id+'.jpg'" alt="" :class="{'border-bloc-selected' : (material.selected)}" style="height:100px;">-->
                  </div>
                  <!-- <div
                    v-if="material.est_elec != 'Non' && material.id != 11"
                    class="family-bloc-legend"
                  >
                    Taille {{ material.size }} ({{ material.cm }})
                  </div> -->
                  {{ material.nom }}
                  <div v-if="material.est_elec == 'Non'" class="family-bloc-legend">
                    Sans assistance électrique
                  </div>
                  <div v-if="material.est_elec != 'Non' && material.id == 11" class="family-bloc-legend">
                    Cargo électrique
                  </div>
                </div>
                <div v-if="material.qty <= 0">
                  <div class="family-bloc-icon">
                    <img :src="'./media/velo_' + material.id + '.jpg'"
                      :src-placeholder="'./media/velo_' + material.id + '.jpg'" alt=""
                      :class="{ 'border-bloc-selected': material.selected }"
                      style="height: 100px; filter: opacity(0.3)" height="100px" />
                    <!--<img :src="'./media/velo_'+material.id+'.jpg'" alt="" :class="{'border-bloc-selected' : (material.selected)}" style="height:100px;filter:grayscale(1);">-->
                  </div>
                  <div v-if="material.est_elec != 'Non' && material.id != 11" class="family-bloc-legend">
                    <b> {{ material.nom }} </b>
                  </div>
                  <div v-if="material.est_elec == 'Non'" class="family-bloc-legend">
                    <b>Indisponible</b> - Sans assistance électrique
                  </div>
                  <div v-if="material.est_elec != 'Non' && material.id == 11" class="family-bloc-legend">
                    <b>Indisponible</b> - Cargo électrique
                  </div>
                </div>
                <div class="family-bloc-select"></div>
                <div class="family-bloc-legend" style="padding:0px 5px">{{ material.titre2 }}</div>
              </li>
            </ul>
            <ul class="family-bloc-text">
              <div align="center" class="account-bloc-title">
                VAE Spéciaux
                <div style="color: red; font-size: 13px; padding: 0px; margin: 0px"
                  v-if="account.nb_contrats_special > 0">
                  ( Un contrat VAE Spécial est déja en cours )
                </div>
              </div>
              <li v-for="material in list_materials_special" :key="material.id"
                @click="validS1(material, account.nb_contrats_special)" style="border: none">
                <div class="family-bloc-title">{{ material.titre1 }}</div>
                <div v-if="material.qty > 0">
                  <div class="family-bloc-icon">
                    <img :src="'./media/velo_' + material.id + '.jpg'"
                      :src-placeholder="'./media/velo_' + material.id + '.jpg'" alt=""
                      :class="{ 'border-bloc-selected': material.selected }" style="height: 100px" />
                    <!--<img :src="'./media/velo_'+material.id+'.jpg'" alt="" :class="{'border-bloc-selected' : (material.selected)}" style="height:100px;">-->
                  </div>
                  <!-- <div
                    v-if="material.est_elec != 'Non' && material.id != 11"
                    class="family-bloc-legend"
                  >
                    Taille {{ material.size }} ({{ material.cm }})
                  </div> -->
                  {{ material.nom }}
                  <div v-if="material.est_elec == 'Non'" class="family-bloc-legend">
                    Sans assistance électrique
                  </div>
                  <div v-if="material.est_elec != 'Non' && material.id == 11" class="family-bloc-legend">
                    Cargo électrique
                  </div>
                </div>
                <div v-if="material.qty <= 0">
                  <div class="family-bloc-icon">
                    <img :src="'./media/velo_' + material.id + '.jpg'"
                      :src-placeholder="'./media/velo_' + material.id + '.jpg'" alt=""
                      :class="{ 'border-bloc-selected': material.selected }"
                      style="height: 100px; filter: opacity(0.3)" height="100px" />
                    <!--<img :src="'./media/velo_'+material.id+'.jpg'" alt="" :class="{'border-bloc-selected' : (material.selected)}" style="height:100px;filter:grayscale(1);">-->
                  </div>
                  <div v-if="material.est_elec != 'Non' && material.id != 11" class="family-bloc-legend">
                    <b> {{ material.nom }} </b>
                  </div>
                  <div v-if="material.est_elec == 'Non'" class="family-bloc-legend">
                    <b>Indisponible</b> - Sans assistance électrique
                  </div>
                  <div v-if="material.est_elec != 'Non' && material.id == 11" class="family-bloc-legend">
                    <b>Indisponible</b> - Cargo électrique
                  </div>
                </div>
                <div class="family-bloc-select"></div>
                <div class="family-bloc-legend">{{ material.titre2 }}</div>
              </li>
            </ul>
            <div id="blocacc" v-if="JSON.stringify(material_selected) != '{}' &&
              material_selected.accessories.length > 0
              " class="account-bloc-title" style="border-top: solid 1px var(--C3)">
              <label style="font-size: 18px">Accessoires</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Sélectionnez les accessoires au nombre de
                {{ material_selected.qtymaxaccessories }} au maximum
              </p>
            </div>
            <ul v-if="JSON.stringify(material_selected) != '{}' &&
              material_selected.accessories.length > 0
              " class="accessory-bloc-text">
              <li v-for="acc in material_selected.accessories" :key="acc.id">
                <div class="family-bloc-title">{{ acc.label }}</div>
                <ul v-if="acc.qties.length > 1" class="accessory-bloc-qty">
                  <li v-for="q in acc.qties" :key="q.value" @click="validS1acc(acc, q)">
                    <label :class="{ 'border-bloc-selected': q.selected == true }">{{ q.value }}</label>
                  </li>
                </ul>
                <ul v-if="acc.qties.length == 1" class="accessory-bloc-qty">
                  <li v-for="q in acc.qties" :key="q.value" @click="validS1acc(acc, q)">
                    <label>Indisponible</label>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="material_selected.qty <= 0" class="line-a" style="margin-bottom: 20px">
          <div class="account-bloc" style="border-color: red">
            <div class="account-bloc-title">
              <!-- Nous n'avons plus de {{ material_selected.nom }} disponible,
              veuillez finaliser toutes les étapes afin de vous inscrire sur
              notre liste d'attente. -->
              finaliser votre demande, veuillez-vous inscrire sur la liste d’attente en renseignant les étapes suivantes.
            </div>
          </div>
        </div>
        <!-- <div
          id="bloc2"
          v-if="step >= 2 && material_selected.qty != 0"
          class="line-a"
          style="margin-bottom: 40px"
        >
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Etape 2 / {{ maxstep }}</label>
              <p
                style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                "
              >
                Sélectionnez la date souhaitée de retrait
              </p>
            </div>
            <div
              class="form-input"
              style="
                margin: 0px;
                padding: 0px;
                margin-left: calc(50% - 160px);
                width: 320px;
              "
            >
              <datepicker
                style="
                  margin-top: 0px;
                  padding-left: 0px;
                  border: none;
                  margin-bottom: 40px;
                "
                :language="lgdate"
                format="dd/MM/yyyy"
                :class="{
                  'finput-form': true,
                  'finput-error': dateoutofbound != '',
                }"
                v-model="date_retrait"
                :disabled-dates="state.disabledDates"
                placeholder="Date de retrait"
                @input="validS2(date_retrait)"
              ></datepicker>
            </div>
          </div>
        </div> -->

        <div id="bloc3" v-if="step >= 3 && material_selected.qty > 0" class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Etape 2 / {{ maxstep }}</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Sélectionnez le type d'abonnement
              </p>
            </div>
            <div v-for="subscription in material_selected.subscriptions" v-bind:key="subscription.id"
              @click="validS3(subscription)">
              <div :class="{
                'account-bloc-text': true,
                'bloc-selected': subscription.selected == true,
              }" v-if="subscription.payment.tarif_plein.visible === undefined ||
  subscription.payment.tarif_plein.visible === 'Oui'
  ">
                <div class="account-bloc-legend">
                  ABONNEMENT {{ subscription.duration }}
                  {{ subscription.type_duration }}
                </div>
                <div class="account-bloc-value" style="font-size: 12px; color: var(--C2)">
                  <!-- <div style="float: left">
                    Durée de {{ subscription.duration }}
                    {{ subscription.type_duration }}
                  </div> -->
                  <div align="right">
                    <div v-for="payment_type in subscription.payment" :key="payment_type.id">
                      <div v-if="payment_type.id === 1" style="font-size: 15px; font-weight: bold">
                        {{ payment_type.value }} €
                      </div>
                      <div v-if="payment_type.id !== 1 && payment_type.value"
                        style="font-size: 12px; color: grey; float: right">
                        {{ payment_type.name }} {{ payment_type.value }} €
                      </div>
                    </div>
                  </div>
                </div>
                <div class="account-bloc-icon">
                  <fa-icon :style="{ 'font-size': icon_size + 'px' }" v-if="!(subscription.selected == true)"
                    :icon="['far', 'circle']" />
                  <fa-icon :style="{ 'font-size': icon_size + 'px' }" v-if="subscription.selected == true"
                    :icon="['far', 'check-circle']" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="bloc4" v-if="step >= 4 && material_selected.qty > 0" class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Etape 3 / {{ maxstep }}</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Sélectionnez le type de tarif
              </p>
            </div>
            <div v-for="subscription in material_selected.subscriptions" :key="subscription.id">
              <div v-if="subscription.selected">
                <div v-for="payment in subscription.payment" v-bind:key="payment.id" :class="{
                  'account-bloc-text': true,
                  'bloc-selected': payment.selected == true,
                }" @click="validS4(payment)">
                  <div v-if="payment.value" class="account-bloc-legend">
                    {{ payment.name }}
                  </div>
                  <div v-if="payment.value" class="account-bloc-value" style="font-size: 12px; color: var(--C2)">
                    <div style="width: 90%; float: left">
                      <!-- {{ payment.label }} -->
                      {{ payment.description }}
                    </div>
                    <div style="
                        float: right;
                        font-size: 15px;
                        font-weight: bold;
                        width: 10%;
                      " align="right">
                      {{ payment.value }} €
                    </div>
                  </div>
                  <div v-if="payment.value" class="account-bloc-icon">
                    <fa-icon :style="{ 'font-size': icon_size + 'px' }" v-if="!(payment.selected == true)"
                      :icon="['far', 'circle']" />
                    <fa-icon :style="{ 'font-size': icon_size + 'px' }" v-if="payment.selected == true"
                      :icon="['far', 'check-circle']" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="bloc5" v-if="step >= 5" class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label v-if="material && material.qty > 0" style="font-size: 18px">Etape 4 / {{ maxstep }}</label>
              <label v-else style="font-size: 18px">Etape 2 / 3</label>

              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Lieu de retrait, de maintenance et de rendu du vélo
              </p>
            </div>
            <div v-for="agencie in list_agencies_ordered" :key="agencie.id" :class="{
              'account-bloc-text': true,
              'bloc-selected': agencie.selected == true,
            }" @click="validS5(agencie)">
              <!-- {{ agencie }} -->
              <div class="account-bloc-legend">{{ agencie.Adresse }}</div>
              <div class="account-bloc-value" style="font-size: 12px; color: var(--C2)">
                {{ agencie.Nom }}
                <!-- <div style="float: right; font-size: 15px; font-weight: bold">
                  {{ agencie.price }} €
                </div> -->
              </div>
              <div class="account-bloc-icon">
                <fa-icon :style="{ 'font-size': icon_size + 'px' }" v-if="!(agencie.selected == true)"
                  :icon="['far', 'circle']" />
                <fa-icon :style="{ 'font-size': icon_size + 'px' }" v-if="agencie.selected == true"
                  :icon="['far', 'check-circle']" />
              </div>
            </div>
          </div>
        </div>

        <div id="bloc6" v-if="step >= 6" class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label v-if="material && material.qty > 0" style="font-size: 18px">Etape 5 / {{ maxstep }}</label>
              <label v-else style="font-size: 18px">Etape 3 / 3</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Insérer les documents adminitratifs nécessaires pour la location
              </p>
            </div>

            <div v-if="material_selected.qty <= 0 || subscription_selected.id >= 1">
              <div class="account-bloc-text" @click="editprofile" v-if="material_selected.qty > 0">
                <div class="account-bloc-legend">IBAN</div>
                <div v-if="account.iban == ''" style="color: red; font-weight: bold" class="account-bloc-value">
                  Veuillez renseigner votre IBAN
                </div>
                <div v-if="account.iban != ''" class="account-bloc-value">
                  <div class="account-bloc-value" style="color: red; font-weight: bold; margin-top: -21px"
                    v-if="is_valid_iban === false">
                    <i class="fa fa-info-circle"></i> Iban non valide
                  </div>
                  <div class="">{{ account.iban }}</div>
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <div v-if="piece_rib == true" class="account-bloc-text">
                <div class="account-bloc-legend">RIB</div>
                <div class="account-bloc-value" @click="downloadFile('rib')">
                  Consulter le document
                </div>
                <div class="account-bloc-icon" @click="uploadFile('rib')">
                  <i class="fa fa-upload"></i>
                </div>
              </div>
              <div v-if="piece_rib == false" class="account-bloc-text" @click="uploadFile('rib')">
                <div class="account-bloc-legend">RIB</div>
                <div v-if="material_selected.qty > 0" class="account-bloc-value" style="color: red; font-weight: bold">
                  <i class="fa fa-info-circle"></i> Document manquant
                </div>
                <div v-else class="account-bloc-value">
                  <!-- Document optionel -->
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>

              <div v-if="piece_cni == true" class="account-bloc-text">
                <div class="account-bloc-legend">
                  CNI / Passeport en cours de validité
                </div>
                <div class="account-bloc-value" @click="downloadFile('cni')">
                  Consulter le document
                </div>
                <div class="account-bloc-icon" @click="uploadFile('cni')">
                  <i class="fa fa-upload"></i>
                </div>
              </div>
              <div v-if="piece_cni == false" class="account-bloc-text" @click="uploadFile('cni')">
                <div class="account-bloc-legend">
                  CNI / Passeport en cours de validité
                </div>
                <div v-if="material_selected.qty > 0" class="account-bloc-value" style="color: red; font-weight: bold">
                  <i class="fa fa-info-circle"></i> Document manquant
                </div>
                <div v-else class="account-bloc-value">
                  <!-- Document optionel -->
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <div v-if="piece_justificatif == true" class="account-bloc-text">
                <div class="account-bloc-legend">
                  Justificatif de domicile de moins de 3 mois
                </div>
                <div class="account-bloc-value" @click="downloadFile('justificatif')">
                  Consulter le document
                </div>
                <div class="account-bloc-icon" @click="uploadFile('justificatif')">
                  <i class="fa fa-upload"></i>
                </div>
              </div>
              <div v-if="piece_justificatif == false" class="account-bloc-text" @click="uploadFile('justificatif')">
                <div class="account-bloc-legend">
                  Justificatif de domicile de moins de 3 mois
                </div>
                <div v-if="material_selected.qty > 0" class="account-bloc-value" style="color: red; font-weight: bold">
                  <i class="fa fa-info-circle"></i> Document manquant
                </div>
                <div v-else class="account-bloc-value">
                  <!-- Document optionel -->
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <div v-if="piece_responsabilite_civil == true" class="account-bloc-text">
                <div class="account-bloc-legend">
                  Attestation de responsabilité civile
                </div>
                <div class="account-bloc-value" @click="downloadFile('responsabilite_civil')">
                  Consulter le document
                </div>
                <div class="account-bloc-icon" @click="uploadFile('responsabilite_civil')">
                  <i class="fa fa-upload"></i>
                </div>
              </div>
              <div v-if="piece_responsabilite_civil == false" class="account-bloc-text"
                @click="uploadFile('responsabilite_civil')">
                <div class="account-bloc-legend">
                  Attestation de responsabilité civile
                </div>
                <div v-if="material_selected.qty > 0" class="account-bloc-value" style="color: red; font-weight: bold">
                  <i class="fa fa-info-circle"></i> Document manquant
                </div>
                <div v-else class="account-bloc-value">
                  <!-- Document optionel -->
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <div v-if="(selected_payment && selected_payment.id !== 1) ||
                material_selected.qty === 0
                ">
                <div v-if="reduction_justificatif == true" class="account-bloc-text">
                  <div class="account-bloc-legend">
                    Justificatif de réduction
                  </div>
                  <div class="account-bloc-value" @click="downloadFile('reduction')">
                    Consulter le document
                    <div style="font-size: 12px; color: grey">
                      ( Attestation ou carte étudiante, attestation de demandeur
                      d'emploi )
                    </div>
                  </div>
                  <div class="account-bloc-icon" @click="uploadFile('reduction')">
                    <i class="fa fa-upload"></i>
                  </div>
                </div>
                <div v-if="reduction_justificatif == false" class="account-bloc-text" @click="uploadFile('reduction')">
                  <div class="account-bloc-legend">
                    Justificatif de réduction
                  </div>
                  <div v-if="material_selected.qty > 0" class="account-bloc-value" style="color: red; font-weight: bold">
                    <i class="fa fa-info-circle"></i> Document manquant
                    <div style="font-size: 12px; color: grey">
                      ( Attestation ou carte étudiante, attestation de demandeur
                      d'emploi )
                    </div>
                  </div>
                  <div v-else class="account-bloc-value">

                    <!-- Document optionel -->
                    (Attestation ou carte étudiante,
                    attestation de demandeur d'emploi)
                  </div>
                  <div class="account-bloc-icon">
                    <i class="fa fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="step >= 6 && account.is_main_bank == 'non'" class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Informations concernant le titulaire du compte bancaire</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Fournir les informations complémentaires concernant le titulaire
                du compte
              </p>
            </div>
            <div v-if="banq_account.nom == ''" class="account-bloc-text" @click="editbanqaccount">
              <div class="account-bloc-legend">TITULAIRE DU COMPTE</div>
              <div style="color: red; font-weight: bold" class="account-bloc-value" v-if="material_selected.qty > 0">
                <i class="fa fa-info-circle"></i> Veuillez renseigner les
                informations du titulaire du compte
              </div>
              <div class="account-bloc-icon">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
            <div v-if="banq_account.nom != ''" class="account-bloc-text" @click="editbanqaccount">
              <div class="account-bloc-legend">TITULAIRE DU COMPTE</div>
              <div class="account-bloc-value">
                {{ banq_account.prenom }} {{ banq_account.nom }}
              </div>
              <div class="account-bloc-icon">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
            <div v-if="piece_cni_titulaire == true" class="account-bloc-text">
              <div class="account-bloc-legend">
                CNI / Passeport en cours de validité
              </div>
              <div class="account-bloc-value" @click="downloadFile('cni_titulaire')">
                Consulter le document
              </div>
              <div class="account-bloc-icon" @click="uploadFile('cni_titulaire')">
                <i class="fa fa-upload"></i>
              </div>
            </div>
            <div v-if="piece_cni_titulaire == false" class="account-bloc-text" @click="uploadFile('cni_titulaire')">
              <div class="account-bloc-legend">
                CNI / Passeport en cours de validité
              </div>
              <div class="account-bloc-value" style="color: red; font-weight: bold">
                <i class="fa fa-info-circle"></i> Insérer la CNI ou le passeport
                du titulaire du compte bancaire
              </div>
              <div class="account-bloc-icon">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
            <div v-if="piece_attestation == true" class="account-bloc-text">
              <div class="account-bloc-legend">Procuration</div>
              <div class="account-bloc-value" @click="downloadFile('attestation')">
                Consulter le document
              </div>
              <div class="account-bloc-icon" @click="editbanqfile">
                <i class="fa fa-upload"></i>
              </div>
            </div>
            <div v-if="piece_attestation == false" class="account-bloc-text" @click="editbanqfile">
              <div class="account-bloc-legend">Procuration</div>
              <div class="account-bloc-value" style="color: red; font-weight: bold">
                <i class="fa fa-info-circle"></i> Télécharger et insérer le
                formulaire de procuration complété et signé
              </div>
              <div class="account-bloc-icon">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
        <div id="bloc_recap" v-if="step >= 6 && (visu_payment || material_selected.qty <= 0)" class="line-a"
          style="margin-bottom: 40px; padding-bottom: 40px">
          <div class="account-bloc">
            <div v-if="material_selected.qty > 0">
              <div class="recap-bloc-title">
                <label style="font-size: 18px">Récaptitulatif</label>
                <p style="
                    color: var(--C2);
                    font-size: 13px;
                    padding: 0px;
                    margin: 0px;
                  ">
                  Récapitulatif de votre réservation
                </p>
              </div>
              <div class="recap-bloc-title" style="background-color: var(--BUTTON); border-bottom: none">
                <!-- <label style="font-size: 18px; color: #fff"
                  ><i class="fa fa-exclamation"></i
                ></label>
                <p
                  style="
                    color: #fff;
                    font-size: 13px;
                    padding: 0px;
                    margin: 0px;
                  "
                >
                  Le retrait du vélo se fait uniquement sur rendez-vous, une
                  fois que les pièces auront été vérifiées et validées et le
                  paiement de la totalité de la location effectué. En cas de
                  vélo indisponible, vous pouvez vous inscrire sur liste
                  d’attente, en sélectionnant le modèle de vélo demandé
                  ci-dessus.
                </p>
                <br /> -->
                <p style="
                    color: #fff;
                    font-size: 13px;
                    padding: 0px;
                    margin: 0px;
                  ">
                  Un mandat de prélèvement SEPA sera à signer lors du retrait du
                  vélo, il servira de caution.
                  <!-- La caution sera encaissée, puis restituée en fin de location,
                  une fois l'état des lieux du vélo fait. -->
                  <!-- <a style="font-weight: bold; color: white">caution</a>. -->
                </p>
                <br />
                <p style="
                    color: darkred;
                    font-size: 13px;
                    padding: 0px;
                    margin: 0px;
                  ">
                  <a style="font-weight: bold; color: red">ATTENTION !</a>
                </p>
                <p style="
                    color: darkred;
                    font-size: 13px;
                    padding: 0px;
                    margin: 0px;
                  ">
                  Si vous utilisez le navigateur
                  <a style="font-weight: bold; color: red">Chrome</a>, une page
                  d’erreur pourrait apparaitre après votre paiement. Votre
                  paiement aura tout de même été validé, vous pourrez le
                  vérifier via le mail de confirmation que avez allez recevoir.
                </p>
              </div>
              <div class="recap-bloc-text" style="
                  background-color: var(--C2);
                  color: #fff;
                  border-top: none;
                ">
                <div style="
                    padding: 20px;
                    text-align: center;
                    width: calc(100% - 40px);
                    float: left;
                  ">
                  <label style="font-size: 16px">{{ lbl_description }}</label>
                  <br />
                  <label style="font-size: 32px">{{ lbl_amount | money }}</label><br />
                  <label style="font-size: 12px">{{
                    lbl_payment_info_cb
                  }}</label><br />
                  <label style="font-size: 12px">la caution s'élèvera à
                    {{ lbl_caution_amount | money }}</label>
                </div>
              </div>
            </div>
            <div class="recap-bloc-text" style="background-color: #fff; color: var(--C2); border-top: none">
              <div style="
                  padding: 20px;
                  text-align: center;
                  width: calc(100% - 40px);
                  float: left;
                ">
                <label v-if="acceptcguv" style="font-size: 16px">
                  <fa-icon :icon="['fas', 'check-square']" @click="acceptcguv = !acceptcguv" />
                  J'ai lu et j'accepte le
                  <a style="
                      text-decoration: underline;
                      color: var(--BUTTON);
                      font-weight: bold;
                      cursor: pointer;
                    " @click="downloadFile('Reglement_RGPD')">
                    règlement de service et la politique de confidentialité</a>
                </label>
                <label v-if="!acceptcguv" style="font-size: 16px">
                  <fa-icon :icon="['fas', 'square']" @click="acceptcguv = !acceptcguv" />
                  J'ai lu et j'accepte le
                  <a target="_blank" style="
                      text-decoration: underline;
                      color: var(--BUTTON);
                      font-weight: bold;
                      cursor: pointer;
                    " @click="downloadFile('Reglement_RGPD')">
                    règlement de service et la politique de confidentialité</a>
                </label>
              </div>
            </div>
            <div class="recap-bloc-text" style="
                padding-top: 20px;
                padding-bottom: 20px;
                text-align: center;
                width: 100%;
              ">
              <div v-if="material_selected.qty > 0" style="
                  padding: 10px;
                  text-align: center;
                  width: calc(100% - 20px);
                  float: left;
                ">
                <button v-if="acceptcguv" @click="payment_start" class="fbutton fbutton-big">
                  Régler la réservation
                </button>
                <button v-if="!acceptcguv" class="fbutton fbutton-big" style="background-color: var(--C2)">
                  Régler la réservation
                </button>
              </div>
              <div v-else style="
                  padding: 10px;
                  text-align: center;
                  width: calc(100% - 20px);
                  float: left;
                ">
                <button v-if="!acceptcguv" class="fbutton fbutton-big" style="background-color: var(--C2)">
                  S'inscrire sur la liste d'attente
                </button>
                <button v-if="acceptcguv" class="fbutton fbutton-big" @click="goNOSTOCK">
                  S'inscrire sur la liste d'attente
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="viewdiv == 'retour'">
        <div class="line" style="text-align: center; margin-top: 120px">
          <i v-if="msgretour == 'effectue'" style="color: var(--BUTTON)" class="far fa-smile-beam fa-4x"></i>
          <i v-if="msgretour != 'effectue'" style="color: var(--BUTTON)" class="far fa-frown fa-4x"></i>
          <h1>{{ msgtitle }}</h1>
          <h3>{{ msgsubtitle }}</h3>
          <button class="fbutton" @click="backAccount">
            Retour à votre compte
          </button>
        </div>
      </div>
      <div v-if="viewdiv == 'nostock'">
        <div class="line" style="text-align: center; margin-top: 120px">
          <i style="color: var(--BUTTON)" class="far fa-frown fa-4x"></i>
          <h1>Indisponible</h1>
          <h3>
            Nous n'avons plus du vélo électrique de disponible, veuillez cliquer
            sur le bouton ci-dessous afin de vous inscrire dans notre liste
            d'attente.
          </h3>
          <button style="margin-right: 10px" class="fbutton" @click="goNOSTOCK">
            S'inscrire sur la liste d'attente
          </button>
          <button class="fbutton" @click="backAccount">
            Retour à votre compte
          </button>
        </div>
      </div>
      <div v-if="viewdiv == 'nostock_valid'">
        <div class="line" style="text-align: center; margin-top: 120px">
          <i style="color: var(--BUTTON)" class="far fa-smile-beam fa-4x"></i>
          <h1>Inscription dans la liste d'attente</h1>
          <h3>
            Votre inscription dans la liste d'attente a bien été effectuée.
          </h3>
          <button class="fbutton" @click="backAccount">
            Retour à votre compte
          </button>
        </div>
      </div>
    </div>
    <dataform v-if="JSON.stringify(ctrldataform) != '{}'" :title="ctrldataform.title" :controls="ctrldataform.controls"
      :fxback="ctrldataform.fxback" :fxsave="ctrldataform.fxsave"></dataform>
    <loadingform v-if="loading"></loadingform>
  </div>
</template>

<script>
import engine from "@/core/data/DataAPI";
import dataform from "@/components/DrawerRight";
// import datepicker from "vuejs-datepicker";
import loadingform from "@/core/controls/LoadingForm";
// import { fr } from "vuejs-datepicker/dist/locale";
import MenuTop from "@/components/MenuTop";

export default {
  components: {
    MenuTop,
    dataform,
    loadingform,
    // datepicker,
  },
  data() {
    return {
      state: {
        disabledDates: {},
      },
      iban_information: undefined,
      nbr_contrats_max: 1,
      icon_size: 12,
      loading: false,
      // lgdate: fr,
      subscriptions: undefined,
      selected_payment: undefined,
      delivery_place_selected: undefined,
      acceptcguv: false,
      msgtitle: "",
      msgsubtitle: "",
      msgretour: "",
      account: {},
      vdate: "",
      datenow: "",
      viewdiv: "reservation",
      maxstep: 5,
      currentstep: 1,
      piece_rib: false,
      piece_cni: false,
      piece_responsabilite_civil: false,
      piece_justificatif: false,
      reduction_justificatif: false,
      piece_attestation: false,
      piece_cni_titulaire: false,
      date_retrait: "",
      date_fin: "",
      mois_2: 0,
      mois_3: 0,
      step: 1,
      list_materials: [],
      list_agencies: [],
      list_prices: [],
      ctrldataform: {},
      banq_account: {
        nom: "",
        prenom: "",
        adresse: "",
        cp: "",
        ville: "",
        email: "",
        telephone: "",
        lien_parente: "",
        id_fichier: engine.Guid(),
      },
    };
  },
  watch: {
    step(val) {
      console.log("val", val);
      if (val === 6) {
        this.iban_verification();
      }
    },
  },
  computed: {
    list_materials_vae() {
      return this.list_materials.filter((val) => val.famille == "VELO");
    },
    list_materials_special() {
      return this.list_materials.filter((val) => val.famille == "VELO SPECIAL");
    },
    is_valid_iban() {
      console.log("iban_information", this.iban_information);
      if (
        this.iban_information &&
        this.iban_information.valid == true
      )
        return true;
      return false;
    },
    list_agencies_ordered() {
      let agencies_list_orderer = [...this.list_agencies];
      agencies_list_orderer = agencies_list_orderer.sort(function (a, b) {
        return a.order - b.order;
      });
      return agencies_list_orderer;
    },
    visu_payment: function () {
      var tmain = this;
      var ret = false;

      console.log("is_valid_iban", tmain.is_valid_iban);
      if (tmain.step >= 5) {
        console.log(0);
        console.log("piece_cni", tmain.piece_cni );
        console.log("piece_responsabilite_civil", tmain.piece_responsabilite_civil );
        console.log("iban", tmain.account.iban );
        console.log("bic", tmain.account.bic  );
        console.log("is_valid_iban", tmain.is_valid_iban );
        console.log("piece_rib", tmain.piece_rib );
        // console.log("piece_cni", tmain.piece_cni );
        if (
          tmain.piece_cni &&
          tmain.piece_responsabilite_civil &&
          tmain.account.iban != "" &&
          // tmain.account.bic != "" &&
          tmain.is_valid_iban &&
          tmain.piece_rib
          // &&
          // (tmain.reduction_justificatif ||
          //   tmain.material_selected.qty == 0 ||
          //   !tmain.reduction_justificatif)
        ) {
          console.log(1);
          if (tmain.account.is_main_bank == "oui") {
            ret = true;
            console.log(2);
          } else {
            if (
              tmain.banq_account.nom != "" &&
              tmain.piece_attestation &&
              tmain.piece_cni_titulaire
            ) {
              console.log(3);
              ret = true;
            }
          }
        }
      }
      console.log("ret", ret);
      return ret;
    },
    material_selected: function () {
      var tmain = this;
      var info = tmain.list_materials.find((x) => x.selected == true);
      if (info == undefined) {
        info = {};
      }
      return info;
    },
    subscription_selected: function () {
      var tmain = this;
      var ret = {};
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        var infret = mat.subscriptions.find((x) => x.selected == true);
        if (infret !== undefined) {
          ret = infret;
        }
      }
      return ret;
    },
    price_selected: function () {
      var tmain = this;
      var ret = {};
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        var infsub = mat.subscriptions.find((x) => x.selected == true);
        if (infsub !== undefined && infsub.prices) {
          var infprice = infsub.prices.find((x) => x.selected == true);
          if (infprice !== undefined) {
            ret = infprice;
          }
        }
      }
      return ret;
    },
    agency_selected: function () {
      var tmain = this;
      var info = tmain.list_agencies.find((x) => x.selected == true);
      if (info == undefined) {
        info = {};
      }
      return info;
    },
    lbl_description: function () {
      var tmain = this;
      var ret = "";
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        // ret += "1 x " + mat.label + " - ";
        ret += "1 x " + mat.nom + " - ";
        mat.accessories.forEach(function (acc) {
          if (acc.qties.find((x) => x.selected == true).value > 0) {
            ret +=
              acc.qties.find((x) => x.selected == true).value +
              " x " +
              acc.label +
              " - ";
          }
        });
      }
      return ret.substring(0, ret.length - 3);
    },
    lbl_amount: function () {
      var tmain = this;
      var ret = 0;
      if (tmain.selected_payment) {
        ret += tmain.selected_payment.value;
      }
      if (
        tmain.delivery_place_selected &&
        tmain.delivery_place_selected.price
      ) {
        ret += tmain.delivery_place_selected.price;
      }
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        var infsub = mat.subscriptions.find((x) => x.selected == true);
        if (infsub !== undefined && infsub.prices) {
          var infprice = infsub.prices.find((x) => x.selected == true);
          if (infprice !== undefined) {
            ret += infprice.amount;
          }
        }
      }
      return ret;
    },
    lbl_caution_amount: function () {
      var tmain = this;
      var ret = 0;
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (
        mat !== undefined &&
        tmain.selected_payment &&
        tmain.selected_payment.name
      ) {
        ret = mat.caution[tmain.selected_payment.name];
      }
      return ret;
    },
    lbl_amount_year: function () {
      var tmain = this;
      var ret = 0;
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        var infsub = mat.subscriptions.find((x) => x.selected == true);
        if (infsub !== undefined) {
          var infprice = infsub.prices.find((x) => x.selected == true);
          if (infprice !== undefined) {
            ret += infprice.amount;
          }
        }
      }
      return ret * 11;
    },
    lbl_payment_info_cb: function () {
      var tmain = this;
      var lbl = "";
      var formatter = new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
      switch (tmain.price_selected.type) {
        case 1:
          lbl = formatter.format(tmain.lbl_amount) + " à régler maintenant";
          break;
        case 2:
          lbl =
            formatter.format(tmain.lbl_amount) +
            " à régler maintenant, restant dû le jour de la réservation 55€ par chèques de 5€.";
          break;
        case 3:
          lbl =
            formatter.format(tmain.lbl_amount) +
            " à régler maintenant, restant dû le jour de la réservation ";
          lbl += formatter.format(tmain.mois_2) + " et ";
          lbl +=
            formatter.format(tmain.mois_3) +
            " par chèques, pour les mois suivants.";
          break;
        case 4:
          lbl = formatter.format(tmain.lbl_amount) + " à régler maintenant";
          break;
      }
      return lbl;
    },
    date_start_min: function () {
      var tmain = this;
      var datemin = new Date();
      datemin.setDate(datemin.getDate() + 3);
      return tmain.$moment(datemin).format("YYYY-MM-DD");
    },
    // date_start_max: function () {
    //   var tmain = this;
    //   var datemax = new Date();
    //   datemax.setDate(datemax.getDate() + 30);
    //   return tmain.$moment(datemax).format("YYYY-MM-DD");
    // },
    dateoutofbound: function () {
      return false;
    },
  },
  mounted: function () {
    this.$moment.locale("fr");
  },
  created: function () {
    var tmain = this;

    tmain.datenow = tmain.$moment().format("YYYY-MM-DD");
    if (JSON.stringify(tmain.$route.query) != "{}") {
      tmain.refresh_ret(tmain.$route.query);
    } else {
      tmain.refresh_ui();
    }
  },
  methods: {
    payment_start: function () {
      var tmain = this;
      if (
        tmain.account.fin_contrats >= tmain.datenow
        // ||
        // tmain.account.nb_contrats >= tmain.nbr_contrats_max
      ) {
        alert("Reservation impossible, vous en avez déjà une en cours");
        tmain.$router.push("/board");
      } else {
        // tmain.loading = true;
        // tmain.list_prices[0].amount = tmain.lbl_amount;
        // tmain.date_retrait = tmain.$moment().format('02/28/2022')
        tmain.date_retrait = tmain.$moment().format("MM/DD/YYYY");
        var pkgp = {
          material: tmain.material_selected,
          agencies: tmain.agency_selected,
          prices: tmain.lbl_amount,
          datedebut: tmain.date_retrait,
          datefin: tmain.date_fin,
          payment: tmain.selected_payment,
          options: {
            is_main_bank: tmain.account.is_main_bank,
            banq_account: tmain.banq_account,
          },
        };
        pkgp.material.caution = tmain.lbl_caution_amount;
        // let subscriptions_length = this.subscriptions.duration;
        // if (this.subscriptions.type_duration === "an")
        //   subscriptions_length = subscriptions_length * 12;
        // this.delivery_place_selected.begin_date = this.$moment(
        //   this.date_retrait
        // );
        // this.delivery_place_selected.end_date = this.$moment(
        //   this.date_retrait
        // ).add(subscriptions_length, "M");
        // // var tmain = this;
        // let devis_data = {
        //   payment: this.selected_payment,
        //   delivery: this.delivery_place_selected,
        //   material: this.material_selected,
        //   subscriptions: this.subscriptions,
        // };
        // console.log("devis_data", devis_data);

        engine.Reservation_Create(pkgp, function (pkgcreate) {
          if (pkgcreate.code != -1) {
            var datenow = tmain.$moment().year();
            var amount = tmain.lbl_amount * 100;
            var reference = pkgcreate.data.replace("-", "");
            //var reference = 'RSW-TEST01-'+tmain.$moment().format('HHmm');
            var mail = tmain.account.email;
            var data = {
              mel: mail,
              montant: amount,
              objet: "paiement de votre reservation VIA Velo",
              refdet: reference,
              exer: datenow,
            };
            engine.Payfip_init(data, function (pkg) {
              if (pkg.code == 0) {
                window.open(pkg.data, "_self");
              }
            });
          } else {
            alert("erreur! impossible de créer la réservation");
            // tmain.loading = false;
          }
        });
      }
    },
    refresh_ret: function (params) {
      var tmain = this;
      tmain.loading = true;
      tmain.$router.replace({ query: null });
      tmain.viewdiv = "retour";
      tmain.msgretour = params.state;
      engine.Account_Info(function (pkg) {
        if (pkg.code == -1) {
          tmain.$router.push("/");
        } else {
          tmain.account = pkg.data[0];
          // if (tmain.account.nb_contrats >= tmain.nbr_contrats_max) {
          //   tmain.$router.push("/board");
          // }
          engine.Agency_List(function (pkga) {
            tmain.list_agencies = pkga.data;
            engine.Material_List(function (pkgm) {
              tmain.list_materials = pkgm.data;
              engine.File_Exist("rib", function (pkgp) {
                tmain.piece_rib = pkgp.data;
                engine.File_Exist("cni", function (pkgp1) {
                  tmain.piece_cni = pkgp1.data;
                  engine.File_Exist("justificatif", function (pkgp2) {
                    tmain.piece_justificatif = pkgp2.data;
                    engine.File_Exist("responsabilite_civil", function (pkgp3) {
                      tmain.piece_responsabilite_civil = pkgp3.data;
                      switch (params.state) {
                        case "effectue":
                          if (params.resa_number) {
                            tmain.msgtitle =
                              "Votre réservation " +
                              params.resa_number +
                              " a été effectuée avec succès.";
                            // tmain.msgsubtitle =
                            //   "Vous allez être contacté par notre équipe pour convenir d'un rendez-vous.";
                            tmain.loading = false;
                          } else {
                            tmain.msgtitle =
                              "Votre réservation " +
                              params.resa_number +
                              " a rencontré une erreur.";
                            // tmain.msgsubtitle =
                            //   "Veuillez contacter la VIA Vélo par e-mail au mobilites@valdille-aubigne.fr";
                            tmain.loading = false;
                          }

                          break;
                        case "annule":
                          engine.Reservation_Cancel(params.Ref, function () {
                            tmain.msgtitle = "Annulation";
                            tmain.msgsubtitle =
                              "Vous avez annulé votre demande de réservation du vélo.";
                            tmain.loading = false;
                          });
                          break;
                        case "refuse":
                          tmain.msgtitle =
                            "Votre réservation " +
                            params.Ref +
                            " a rencontré une erreur de paiement.";
                          tmain.msgsubtitle =
                            "Veuillez contacter la VIA Vélo par e-mail mobilites@valdille-aubigne.fr";
                          tmain.loading = false;
                          break;
                      }
                    });
                  });
                });
              });
            });
          });
        }
      });
    },
    refresh_ui: function () {
      var tmain = this;
      tmain.loading = true;
      engine.Account_Info(function (pkg) {
        if (pkg.code != 0) {
          tmain.$router.push("/");
          tmain.loading = false;
        } else {
          tmain.account = pkg.data[0];
          // if (tmain.account.nb_contrats >= tmain.nbr_contrats_max) {
          //   tmain.$router.push("/board");
          // }
          engine.Agency_List(function (pkga) {
            tmain.list_agencies = pkga.data;
            engine.Material_List(function (pkgm) {
              tmain.list_materials = pkgm.data;
              tmain.loading = false;
              // var total_quantity = 0;
              // tmain.list_materials.forEach(function (material) {
              //   total_quantity += material.qty;
              // });
              // if (total_quantity == 0) {
              //   tmain.viewdiv = "nostock";
              // }
            });
          });
        }
        engine.File_Exist("rib", function (pkgp) {
          tmain.piece_rib = pkgp.data;
        });
        engine.File_Exist("cni", function (pkgp1) {
          tmain.piece_cni = pkgp1.data;
        });
        engine.File_Exist("responsabilite_civil", function (pkgp3) {
          tmain.piece_responsabilite_civil = pkgp3.data;
        });
        engine.File_Exist("justificatif", function (pkgp2) {
          tmain.piece_justificatif = pkgp2.data;
        });
        engine.File_Exist("reduction", function (pkgp4) {
          tmain.reduction_justificatif = pkgp4.data;
        });
        /*engine.File_Exist('cmuc',function(pkgp4){
          tmain.piece_cmuc = pkgp4.data;
        });
        engine.File_Exist('passmobilite',function(pkgp5){
          tmain.piece_mobilite = pkgp5.data;
        });*/
      });
    },
    refresh_docs: function () {
      var tmain = this;
      engine.File_Exist("rib", function (pkgp) {
        tmain.piece_rib = pkgp.data;
        engine.File_Exist("cni", function (pkgp1) {
          tmain.piece_cni = pkgp1.data;
          engine.File_Exist("cni_titulaire", function (pkgp12) {
            tmain.piece_cni_titulaire = pkgp12.data;
            engine.File_Exist("justificatif", function (pkgp2) {
              tmain.piece_justificatif = pkgp2.data;
              engine.File_Exist("reduction", function (pkgp4) {
                tmain.reduction_justificatif = pkgp4.data;
                engine.File_Exist("responsabilite_civil", function (pkgp5) {
                  tmain.piece_responsabilite_civil = pkgp5.data;
                  engine.File_Exist(
                    tmain.banq_account.id_fichier,
                    function (pkgp6) {
                      tmain.piece_attestation = pkgp6.data;
                      var options = {
                        container: ".application",
                        easing: "ease-out",
                        offset: -10,
                        force: true,
                        cancelable: true,
                        x: false,
                        y: true,
                      };
                      if (tmain.account.is_main_bank == "non") {
                        if (
                          tmain.piece_attestation &&
                          tmain.piece_cni_titulaire
                        ) {
                          setTimeout(function () {
                            tmain.$scrollTo("#bloc_recap", 500, options);
                          }, 100);
                        } else {
                          setTimeout(function () {
                            tmain.$scrollTo("#bloc6", 500, options);
                          }, 100);
                        }
                      } else {
                        setTimeout(function () {
                          tmain.$scrollTo("#bloc_recap", 500, options);
                        }, 100);
                      }
                    }
                  );
                });
              });
            });
          });
        });
      });
    },
    validS1: function (mat, nbrContrat) {
      console.log("mat", mat);
      console.log("nbrContrat", nbrContrat);
      console.log("validS1", { mat }, { nbrContrat });
      // if (nbrContrat > 0) return;
      var tmain = this;
      tmain.date_retrait = "";
      tmain.acceptcguv = false;
      tmain.list_materials.forEach((mat) => {
        mat.selected = false;
        if (!mat.accessories) mat.accessories = [];
        mat.accessories.forEach((acc) => {
          acc.selected = false;
          acc.qties.forEach((q) => (q.selected = false));
          acc.qties[0].selected = true;
        });
      });
      tmain.qtycumul = 0;
      mat.selected = true;
      var options = {
        container: ".application",
        easing: "ease-out",
        offset: -10,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      };
      // if (mat.id==2){
      var newDateMin = new Date();
      // var newDateMax = new Date();
      var jmin = newDateMin.getDate() + 3;
      var mmin = newDateMin.getMonth();
      var amin = newDateMin.getFullYear();
      // var jmax = newDateMax.getDate() + 30;
      // var mmax = newDateMax.getMonth();
      // var amax = newDateMax.getFullYear();
      tmain.state.disabledDates = {
        to: new Date(amin, mmin, jmin),
        days: [6, 0],
        // from: new Date(amax, mmax, jmax),
      };
      tmain.step = 3;
      if (mat.qty <= 0) {
        tmain.step = 5;
        setTimeout(function () {
          tmain.$scrollTo("#bloc5", 500, options);
        }, 100);
      } else {
        setTimeout(function () {
          tmain.$scrollTo("#bloc3", 500, options);
        }, 100);
      }
      // }
    },
    validS1acc: function (acc, qtyselect) {
      var tmain = this;
      tmain.date_retrait = "";
      acc.qties.forEach((x) => (x.selected = false));

      acc.selected = true;
      qtyselect.selected = true;
      var qtycumul = 0;
      tmain.material_selected.accessories
        .filter((x) => x.selected == true)
        .forEach(function (tacc) {
          tacc.qties
            .filter((q) => q.selected == true)
            .forEach(function (qt) {
              qtycumul += qt.value;
            });
        });

      if (
        tmain.material_selected.accessories &&
        tmain.material_selected.accessories.filter((x) => x.selected == true)
          .length == tmain.material_selected.accessories.length
      ) {
        if (qtycumul > tmain.material_selected.qtymaxaccessories) {
          qtyselect.selected = false;
          tmain.material_selected.accessories
            .filter((x) => x.selected == true)
            .forEach(function (tacc) {
              if (tacc.qties.filter((q) => q.selected == true).length == 0) {
                tacc.selected = false;
                tmain.step = 1;
              }
            });
          alert("trop de qte selectionné");
        } else {
          tmain.step = 3;
          var options = {
            container: ".application",
            easing: "ease-out",
            offset: -10,
            force: true,
            cancelable: true,
            x: false,
            y: true,
          };
          setTimeout(function () {
            tmain.$scrollTo("#bloc2", 500, options);
          }, 100);
        }
      }
    },
    validS2: function (date_retrait) {
      var tmain = this;
      tmain.$moment(date_retrait).format("YYYY-MM-DD");
      tmain.date_retrait = tmain.$moment(date_retrait).format("YYYY-MM-DD");
      tmain.material_selected.subscriptions.forEach(
        (s) => (s.selected = false)
      );
      if (
        // tmain.$moment(date_retrait) <= tmain.$moment(tmain.date_start_max) &&
        tmain.$moment(date_retrait) >= tmain.$moment(tmain.date_start_min)
      ) {
        tmain.step = 3;
        var options = {
          container: ".application",
          easing: "ease-out",
          offset: -10,
          force: true,
          cancelable: true,
          x: false,
          y: true,
        };
        setTimeout(function () {
          tmain.$scrollTo("#bloc3", 500, options);
        }, 100);
      } else {
        tmain.step = 2;
      }
    },
    validS3: function (subs) {
      var tmain = this;
      tmain.selected_payment = undefined;
      tmain.subscriptions = subs;
      tmain.material_selected.subscriptions.forEach(
        (s) => (s.selected = false)
      );
      subs.selected = true;
      tmain.step = 4;
      tmain.list_prices = [];
      var options = {
        container: ".application",
        easing: "ease-out",
        offset: -10,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      };
      setTimeout(function () {
        tmain.$scrollTo("#bloc4", 500, options);
      }, 100);
    },
    validS4: function (selected_payment) {
      let tmain = this;
      for (let i = 0; i < tmain.material_selected.subscriptions.length; i++)
        for (const value of Object.values(
          tmain.material_selected.subscriptions[i].payment
        ))
          value.selected = false;
      selected_payment.selected = true;
      tmain.delivery_place_selected = undefined;
      tmain.validS5(undefined);
      tmain.selected_payment = selected_payment;
      tmain.step = 5;
      var options = {
        container: ".application",
        easing: "ease-out",
        offset: -10,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      };
      setTimeout(function () {
        tmain.$scrollTo("#bloc5", 500, options);
      }, 100);
    },
    validS5: function (delivery_place) {
      let tmain = this;
      tmain.list_agencies.forEach((s) => (s.selected = false));
      if (delivery_place) delivery_place.selected = true;
      tmain.delivery_place_selected = delivery_place;
      tmain.step = 6;
      var options = {
        container: ".application",
        easing: "ease-out",
        offset: -10,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      };

      setTimeout(function () {
        tmain.$scrollTo("#bloc6", 500, options);
      }, 100);
    },
    editprofile: function () {
      var tmain = this;
      tmain.ctrldataform = {
        title: "Modifier vos informations personnelles",
        controls: [
          {
            datatype: "text",
            datavalue: tmain.account.iban,
            databind: "iban",
            description: "IBAN (la longueur doit être de 27 caractères)",
          },
          {
            datatype: "text",
            datavalue: tmain.account.bic,
            databind: "bic",
            description: "BIC",
            display: "none",
          },
          {
            datatype: "select",
            datavalue: tmain.account.is_main_bank,
            databind: "is_main_bank",
            description: "Etes-vous titulaire du compte bancaire ?",
            datalist: [
              { value: "oui", label: "Oui" },
              { value: "non", label: "Non" },
            ],
          },
        ],
        fxback: function () {
          tmain.ctrldataform = {};
        },
        fxsave: function (o) {
          engine.Account_Edit_Bank(o, function () {
            engine.Account_Info(function (pkg) {
              if (pkg.code == -1) {
                tmain.$router.push("/");
              } else {
                tmain.account = pkg.data[0];
                tmain.iban_verification();
                tmain.refresh_docs();
              }
            });
          });
          tmain.ctrldataform = {};
        },
      };
    },
    iban_verification() {
      var tmain = this;
      console.log("Iban_verification", tmain.account.iban);
      let iban = tmain.account.iban
      // if (tmain.account.iban) {
      //   let iban = tmain.account.iban.replace(/ /g, "").toUpperCase();
      //   let fourFirstDigit = iban.substring(0, 4);
      //   let newIban = iban.substring(4, iban.length) + fourFirstDigit;
      //   let ibanWithoutLetters = "";
      //   for (let i = 0; i < newIban.length; i++) {
      //     if (
      //       newIban[i].charCodeAt(0) >= 65 &&
      //       newIban[i].charCodeAt(0) <= 90
      //     ) {
      //       ibanWithoutLetters += newIban[i].charCodeAt(0) - 55;
      //     } else {
      //       ibanWithoutLetters += newIban[i];
      //     }
      //   }
      //   if (tmain.checkIBAN(ibanWithoutLetters) === 1) {
      engine.Iban_verification(iban, function (res) {
        console.log("bic_value res", res);
        if (res.code == 0) {
          tmain.iban_information = {
            iban: iban,
            valid: true,
          }
          if (res.data.bic) {
            tmain.iban_information['bic'] = res.data.bic
            tmain.account.bic = res.data.bic
          }
        } else {
          tmain.iban_information = { valid: false };
        }
      });
      // } else {
      //   tmain.iban_information = { valid: false };
      // }
      // }
    },
    checkIBAN(iban) {
      const parts = iban.match(/.{1,6}/g);
      return parts.reduce((prev, curr) => Number(prev + curr) % 97, "");
    },
    editbanqaccount: function () {
      var tmain = this;
      tmain.ctrldataform = {
        title: "Indiquer les informations du titulaire du compte bancaire",
        controls: [
          {
            datatype: "text",
            datavalue: tmain.account.iban,
            databind: "iban",
            description: "IBAN (la longueur doit être de 27 caractères)",
          },

          {
            datatype: "text",
            datavalue: tmain.banq_account.prenom,
            databind: "prenom",
            description: "Prénom *",
            condvalue: false,
            condmsg: "Le prénom est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.nom,
            databind: "nom",
            description: "Nom de famille *",
            condvalue: false,
            condmsg: "Le nom de famille est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.adresse,
            databind: "adresse",
            description: "Adresse *",
            condvalue: false,
            condmsg: "L'adresse est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.cp,
            databind: "cp",
            description: "Code postal *",
            condvalue: false,
            condmsg: "Le code postal est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.ville,
            databind: "ville",
            description: "Ville *",
            condvalue: false,
            condmsg: "La ville est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.telephone,
            databind: "telephone",
            description: "Téléphone fixe *",
            condvalue: false,
            condmsg: "Le numéro de téléphone est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.email,
            databind: "email",
            description: "Courriel *",
            condvalue: false,
            condmsg: "Le courriel est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.lien_parente,
            databind: "lien_parente",
            description: "Lien de parenté *",
            condvalue: false,
            condmsg: "Le lien de parenté est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
        ],
        fxback: function () {
          tmain.ctrldataform = {};
        },
        fxsave: function (o) {
          tmain.banq_account.prenom = o.prenom;
          tmain.banq_account.nom = o.nom;
          tmain.banq_account.adresse = o.adresse;
          tmain.banq_account.cp = o.cp;
          tmain.banq_account.ville = o.ville;
          tmain.banq_account.telephone = o.telephone;
          tmain.banq_account.email = o.email;
          tmain.banq_account.lien_parente = o.lien_parente;
          tmain.refresh_docs();
          tmain.ctrldataform = {};
        },
      };
    },
    editbanqfile: function () {
      var tmain = this;
      tmain.account.file_attestation = "";
      tmain.ctrldataform = {
        title: "Fournir le formulaire de procuration",
        controls: [
          {
            datatype: "link",
            description:
              "Etape 1 : Télécharger le formulaire vierge de procuration",
            link: "https://val-dille-aubigne.accen.me/media/procuration.docx",
          },
          {
            datatype: "file",
            databind: "file_attestation",
            description:
              "Etape 2 : Envoyer le formulaire de procuration complété et signé",
            condvalue: false,
            condmsg: "Le fichier doit être au format PDF ou image",
            condfx: function (val) {
              return (
                val.type == "application/pdf" ||
                val.type.indexOf("png") > -1 ||
                val.type.indexOf("jpg") > -1 ||
                val.type.indexOf("jpeg") > -1
              );
            },
          },
        ],
        fxback: function () {
          tmain.ctrldataform = {};
        },
        fxsave: function (ctrl) {
          engine.File_Upload(
            ctrl.file_attestation,
            tmain.banq_account.id_fichier,
            function () {
              tmain.ctrldataform = {};
              tmain.refresh_docs();
            }
          );
        },
      };
    },

    downloadFile: function (doctype) {
      var tmain = this;
      if (doctype == "attestation") {
        doctype = tmain.banq_account.id_fichier;
      }
      engine.File_Download(doctype, function (pkgfile) {
        var ctype = pkgfile.headers.map["content-type"];
        var extname = ".pdf";
        if (ctype[0] == "image/png") {
          extname = ".png";
        }
        if (ctype[0] == "image/jpg") {
          extname = ".jpg";
        }
        if (ctype[0] == "image/jpeg") {
          extname = ".jpeg";
        }
        var fileURL = window.URL.createObjectURL(new Blob([pkgfile.data]));
        var download_link = document.createElement("a");
        download_link.href = fileURL;
        download_link.download = doctype + extname;
        download_link.target = "_blank";
        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);
      });
    },
    uploadFile: function (doctype) {
      var tmain = this;
      tmain.account.file_cni = "";
      tmain.account.file_cni_titulaire = "";
      tmain.account.file_justificatif = "";
      tmain.account.file_responsabilite_civil = "";
      tmain.account.file_reduction = "";
      tmain.account.file_rib = "";

      if (doctype == "cni") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_cni",
              description: "CNI / Passeport (au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(ctrl.file_cni, "cni", function () {
              tmain.ctrldataform = {};
              tmain.refresh_docs();
            });
          },
        };
      }
      if (doctype == "cni_titulaire") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_cni_titulaire",
              description: "CNI / Passeport (au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(
              ctrl.file_cni_titulaire,
              "cni_titulaire",
              function () {
                tmain.ctrldataform = {};
                tmain.refresh_docs();
              }
            );
          },
        };
      }
      if (doctype == "justificatif") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_justificatif",
              description: "Justificatif de domicile (au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(
              ctrl.file_justificatif,
              "justificatif",
              function () {
                tmain.ctrldataform = {};
                tmain.refresh_docs();
              }
            );
          },
        };
      }
      if (doctype == "rib") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_rib",
              description:
                "Relevé d'Identité Bancaire (RIB - au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(ctrl.file_rib, "rib", function () {
              tmain.ctrldataform = {};
              tmain.refresh_docs();
            });
          },
        };
      }
      if (doctype == "responsabilite_civil") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_responsabilite_civil",
              description:
                "Attestation de responsabilite civile (au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(
              ctrl.file_responsabilite_civil,
              "responsabilite_civil",
              function () {
                tmain.ctrldataform = {};
                tmain.refresh_docs();
              }
            );
          },
        };
      }
      if (doctype == "reduction") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_reduction",
              description: "Justificatif de réduction (au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(ctrl.file_reduction, "reduction", function () {
              tmain.ctrldataform = {};
              tmain.refresh_docs();
            });
          },
        };
      }
      if (doctype == "attestation") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_attestation",
              description: "Procuration du titulaire du compte bancaire",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(
              ctrl.file_attestation,
              tmain.banq_account.id_fichier,
              function () {
                tmain.ctrldataform = {};
                tmain.refresh_docs();
              }
            );
          },
        };
      }
    },
    backAccount: function () {
      var tmain = this;
      tmain.$router.push("/board");
    },
    goNOSTOCK: function () {
      var tmain = this;
      console.log('mat', this.material);
      engine.Reservation_Nostock(
        this.delivery_place_selected,
        this.material_selected,
        function () {
          tmain.viewdiv = "nostock_valid";
        }
      );
    },
  },
};
</script>
<style></style>
