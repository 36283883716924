<template>
  <div>
    <div v-if="viewdiv == 'login'" class="divlogin">
      <img src="media/logo.png" alt="" style="height: 40px; margin-top: 40px" />
      <h2 style="margin: 0px; margin-top: 5px; margin-bottom: 7px">
        Connexion / S'identifier
      </h2>
      <h3 style="margin: 0px">
        Veuillez vous authentifier pour accéder <br />à votre compte de location
        VIA Vélo.
      </h3>
      <input
        :class="{ 'finput-login': true, 'finput-error': msgpwd != '' }"
        v-model="email"
        type="text"
        placeholder="Adresse e-mail"
        v-on:keyup.13="btlogin"
        ref="email"
      />
      <label
        v-if="msgpwd != ''"
        style="
          color: red;
          width: 400px;
          margin-left: 40px;
          font-size: 14px;
          float: left;
          text-align: left;
        "
        ><i class="fa fa-info-circle"></i> {{ msgpwd }}</label
      >
      <table
        style="
          width: 410px;
          border-spacing: 0;
          padding: 0px;
          margin: 50px 0px 0px 40px;
        "
      >
        <tr>
          <td
            style="text-align: left; padding: 0px; margin: 0px; cell-spacing: 0"
            @click="btlogincreate"
          >
            <label class="flink"
              >Vous n'êtes pas encore client. Créer un compte</label
            >
          </td>
          <td v-if="loading == false" style="text-align: right">
            <button @click="btlogin" class="fbutton">Suivant</button>
          </td>
          <td v-if="loading == true" style="text-align: right">
            <button class="fbutton">
              <i class="fas fa-circle-notch fa-spin"></i>
            </button>
          </td>
        </tr>
      </table>

      <h3>
        Ce service est exclusivement réservé aux habitants de la Communauté de
        Communes Val d’Ille Aubigné.
      </h3>
      <h5 style="padding:0px 10px;">
        (Andouillé-Neuville*Aubigné*Feins*Gahard*Guipel*Langouët*La Mézière <br>
        Melesse*Montreuil-le-Gast*Montreuil-sur-Ille*Mouazé*St-Aubin-d’Aubigné <br>
        St-Germain-sur-Ille*St-Gondran*St-Médard-sur-Ille*St-Symphorien*Sens-de-Bretagne*Vieux-Vy-sur-Couesnon*Vignoc)
      </h5>
    </div>
    <div v-if="viewdiv == 'pwd'" class="divlogin">
      <img src="media/logo.png" alt="" style="height: 40px; margin-top: 40px" />
      <h2 style="margin: 0px; margin-top: 5px; margin-bottom: 7px">
        Bienvenue
      </h2>
      <h3 style="margin: 0px; cursor: pointer" @click="btloginreset">
        {{ email }}
      </h3>
      <input
        :class="{ 'finput-login': true, 'finput-error': msgpwd != '' }"
        v-model="pwd"
        type="password"
        placeholder="Saisissez votre mot de passe"
        v-on:keyup.13="btloginpwd"
      />
      <label
        v-if="msgpwd != ''"
        style="
          color: red;
          width: 400px;
          margin-left: 40px;
          font-size: 14px;
          float: left;
          text-align: left;
        "
        ><i class="fa fa-info-circle"></i> {{ msgpwd }}</label
      >
      <table
        style="
          width: 410px;
          border-spacing: 0;
          padding: 0px;
          margin: 50px 0px 0px 40px;
        "
      >
        <tr>
          <td
            style="text-align: left; padding: 0px; margin: 0px; cell-spacing: 0"
            @click="btloginrecovery"
          >
            <label class="flink">Mot de passe oublié ?</label>
          </td>
          <td v-if="loading == false" style="text-align: right">
            <button @click="btloginpwd" class="fbutton">Suivant</button>
          </td>
          <td v-if="loading == true" style="text-align: right">
            <button class="fbutton">
              <i class="fas fa-circle-notch fa-spin"></i>
            </button>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="viewdiv == 'recovery_ask'" class="divlogin">
      <img src="media/logo.png" alt="" style="height: 40px; margin-top: 40px" />
      <h2 style="margin: 0px; margin-top: 5px; margin-bottom: 7px">
        Mot de passe oublié
      </h2>
      <h3 style="margin: 0px; cursor: pointer">
        Pour réinitialiser votre mot de passe, saisissez l'adresse e-mail que
        vous utilisez pour vous connecter à votre compte VIA Vélo.
      </h3>
      <input
        :class="{ 'finput-login': true, 'finput-error': msgpwd != '' }"
        v-model="email"
        type="text"
        placeholder="Saisissez votre adresse e-mail"
        v-on:keyup.13="btloginrecovery_start"
      />
      <label
        v-if="msgpwd != ''"
        style="
          color: red;
          width: 400px;
          margin-left: 40px;
          font-size: 14px;
          float: left;
          text-align: left;
        "
        ><i class="fa fa-info-circle"></i> {{ msgpwd }}</label
      >
      <table
        style="
          width: 410px;
          border-spacing: 0;
          padding: 0px;
          margin: 50px 0px 0px 40px;
        "
      >
        <tr>
          <td
            style="text-align: left; padding: 0px; margin: 0px; cell-spacing: 0"
            @click="btloginreset"
          >
            <label class="flink">Se connecter avec un compte existant</label>
          </td>
          <td style="text-align: right">
            <button @click="btloginrecovery_start" class="fbutton">
              Envoyer
            </button>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="viewdiv == 'recovery_ask_ok'" class="divlogin">
      <img src="media/logo.png" alt="" style="height: 40px; margin-top: 40px" />
      <h2 style="margin: 0px; margin-top: 5px; margin-bottom: 25px">
        Réinitialisation du mot de passe
      </h2>
      <h3 style="margin: 0px; cursor: pointer">
        Un e-mail a été envoyé à votre adresse <br /><b>{{ email }}</b
        ><br /><br />Suivez les instructions fournies pour réinitialiser<br />votre
        mot de passe<br /><br />
      </h3>
      <button @click="btloginreset" class="fbutton">Terminé</button>
    </div>
    <div v-if="viewdiv == 'recovery_valid'" class="divlogin">
      <img src="media/logo.png" alt="" style="height: 40px; margin-top: 40px" />
      <h2 style="margin: 0px; margin-top: 5px; margin-bottom: 7px">
        Réinitialisation du mot de passe
      </h2>
      <h3 style="margin: 0px; cursor: pointer">
        Veuillez saisir votre nouveau mot de passe<br />pour vous connecter à
        votre compte VIA Vélo.
      </h3>
      <input
        :class="{ 'finput-login': true, 'finput-error': msgpwd != '' }"
        style="margin-top: 20px"
        v-model="pwd"
        type="password"
        placeholder="Votre nouveau mot de passe"
      />
      <label
        v-if="msgpwd != ''"
        style="
          color: red;
          width: 400px;
          margin-left: 40px;
          font-size: 14px;
          float: left;
          text-align: left;
        "
        ><i class="fa fa-info-circle"></i> {{ msgpwd }}</label
      ><br /><br />
      <input
        :class="{ 'finput-login': true, 'finput-error': msgpwdconfirm != '' }"
        style="margin-top: 20px"
        v-model="pwdconfirm"
        type="password"
        placeholder="Confirmer votre nouveau mot de passe"
        v-on:keyup.13="btloginrecovery_valid"
      /><br />
      <label
        v-if="msgpwdconfirm != ''"
        style="
          color: red;
          width: 400px;
          margin-left: 40px;
          font-size: 14px;
          float: left;
          text-align: left;
        "
        ><i class="fa fa-info-circle"></i> {{ msgpwdconfirm }}</label
      ><br /><br />
      <label class="divloginpwd"
        ><i class="fa fa-info-circle"></i> Le mot de passe doit contenir au
        minimum 1 majuscule, 1 minuscule, 1 chiffre, 1 caractère spécial et
        avoir une longueur minimale de 8 caractères.</label
      >
      <table
        style="
          width: 410px;
          border-spacing: 0;
          padding: 0px;
          margin: 50px 0px 0px 40px;
        "
      >
        <tr>
          <td
            style="text-align: left; padding: 0px; margin: 0px; cell-spacing: 0"
            @click="btloginreset"
          >
            <label class="flink">Se connecter avec un compte existant</label>
          </td>
          <td v-if="loading == false" style="text-align: right">
            <button @click="btloginrecovery_valid" class="fbutton">
              Valider
            </button>
          </td>
          <td v-if="loading == true" style="text-align: right">
            <button class="fbutton">
              <i class="fas fa-circle-notch fa-spin"></i>
            </button>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="viewdiv == 'recovery_valid_ok'" class="divlogin">
      <img src="media/logo.png" alt="" style="height: 40px; margin-top: 40px" />
      <h2 style="margin: 0px; margin-top: 5px; margin-bottom: 25px">
        Réinitialisation du mot de passe
      </h2>
      <h3 style="margin: 0px; cursor: pointer">
        Votre mot de passe a été réinitialisé avec succès.<br /><br />Veuillez
        vous authentifier<br />pour accéder à votre compte VIA Vélo.<br /><br />
      </h3>
      <button @click="btloginreset" class="fbutton">Terminé</button>
    </div>
  </div>
</template>

<script>
import engine from "@/core/data/DataAPI";

export default {
  data() {
    return {
      apikey: "",
      loading: false,
      viewdiv: "login",
      email: "",
      pwd: "",
      pwdconfirm: "",
      msgpwd: "",
      msgpwdconfirm: "",
    };
  },
  created: function () {
    var tmain = this;
    if (JSON.stringify(tmain.$route.query) != "{}") {
      switch (tmain.$route.query.state) {
        case "recovery":
          tmain.pwd = "";
          tmain.pwdconfirm = "";
          tmain.msgpwd = "";
          tmain.msgpwdconfirm = "";
          tmain.apikey = tmain.$route.query.apikey;
          tmain.viewdiv = "recovery_valid";
          break;
      }
    }
  },
  methods: {
    btlogin: function () {
      var tmain = this;
      tmain.loading = true;
      tmain.msgpwd = "";
      if (tmain.email != "") {
        engine.Account_Email_Exist(tmain.email, function (pkg) {
          tmain.loading = false;
          if (pkg.code == 0) {
            tmain.email = tmain.email.replace(/ /g, "").trim();
            tmain.msgpwd = "";
            tmain.msgpwdconfirm = "";
            tmain.viewdiv = "pwd";
          } else {
            tmain.msgpwd = "Compte introuvable";
          }
        });
      } else {
        tmain.loading = false;
        tmain.msgpwd = "Veuillez saisir une adresse email";
      }
    },
    btlogincreate: function () {
      var tmain = this;
      tmain.$router.push("/account/create");
    },
    btloginrecovery: function () {
      var tmain = this;
      tmain.email = "";
      tmain.msgpwd = "";
      tmain.msgpwdconfirm = "";
      tmain.pwd = "";
      tmain.viewdiv = "recovery_ask";
    },
    btloginrecovery_start: function () {
      var tmain = this;
      engine.Account_Recovery_Ask(tmain.email, function (pkg) {
        if (pkg.code == 0) {
          tmain.msgpwd = "";
          tmain.viewdiv = "recovery_ask_ok";
        } else {
          tmain.msgpwd = "Compte introuvable";
        }
      });
    },
    btloginrecovery_valid: function () {
      var tmain = this;
      var has_error = false;
      tmain.loading = true;
      tmain.msgpwd = "";
      tmain.msgpwdconfirm = "";
      var regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@.,;#$%&*()]).{8,}/;
      if (!regExp.test(tmain.pwd)) {
        tmain.msgpwd =
          "Veuillez respecter les règles de saisie pour le mot de passe.";
        has_error = true;
      }
      if (tmain.pwd != tmain.pwdconfirm) {
        tmain.msgpwdconfirm = "Les mots de passe sont différents";
        has_error = true;
      }

      if (!has_error) {
        engine.Account_Recovery_Valid(tmain.apikey, tmain.pwd, function (pkg) {
          tmain.loading = false;
          if (pkg.code == 0) {
            tmain.viewdiv = "recovery_valid_ok";
          } else {
            alert("Ce lien n'est plus valide.");
          }
        });
      } else {
        tmain.loading = false;
      }
    },
    btloginreset: function () {
      var tmain = this;
      tmain.email = "";
      tmain.msgpwd = "";
      tmain.msgpwdconfirm = "";
      tmain.pwd = "";
      tmain.pwdconfirm = "";
      tmain.viewdiv = "login";
    },
    btloginpwd: function () {
      var tmain = this;
      tmain.loading = true;
      engine.Account_Login(tmain.email, tmain.pwd, function (pkg) {
        tmain.loading = false;
        if (pkg.code == 0) {
          tmain.msgpwd = "";
          tmain.msgpwdconfirm = "";
          tmain.$router.push("/board");
        } else {
          tmain.msgpwd = "Votre mot de passe est incorrect.";
        }
      });
    },
  },
};
</script>
<style>
</style>