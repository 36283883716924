import Vue from 'vue';
var CryptoJS = require("crypto-js");

class DataAPI {
    constructor() {
        this.URL = "https://viavelo.valdille-aubigne.fr/api";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    Guid() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    }

    IsProduction() {
        return true;
    }

    Account_Login(login, pwd, callback) {
        var datas = { login: login, pwd: pwd };
        Vue.http.post(this.URL + '/v1/account/login', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
            localStorage.setItem("vtoken", res.bodyText.data[0].vtoken)
            localStorage.setItem("vkey", res.bodyText.data[0].vkey)
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }

    Account_Logout(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/account/logout', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
            localStorage.clear()
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Email_Exist(login, callback) {
        var datas = {};
        datas.login = login
        Vue.http.post(this.URL + '/v1/account/exist', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            console.log("res", res);
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Info(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/account/info', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => {
            callback(this.decrypt(reserror));
        });
    }

    Account_Edit(data, callback) {
        var datas = {};
        datas.data = data
        Vue.http.post(this.URL + '/v1/account/edit', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Edit_Bank(data, callback) {
        var datas = {};
        datas.data = data
        Vue.http.post(this.URL + '/v1/account/edit/bank', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Create(data, callback) {
        var datas = {};
        datas.data = data
        Vue.http.post(this.URL + '/v1/account/create', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Create_Valid(apikey, callback) {
        var datas = {};
        datas.apikey = apikey
        Vue.http.post(this.URL + '/v1/account/create/valid', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Recovery_Ask(email, callback) {
        var datas = {};
        datas.email = email
        Vue.http.post(this.URL + '/v1/account/recovery/ask', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Recovery_Valid(apikey, pwd, callback) {
        var datas = {};
        datas.apikey = apikey
        datas.pwd = pwd

        Vue.http.post(this.URL + '/v1/account/recovery/valid', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Inactive(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/account/inactive', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Delete(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/account/delete', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Agency_List(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/agency/list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Material_List(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/material/list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Rentals_byId(id_contrat, callback) {
        var datas = { id_contrat };
        Vue.http.post(this.URL + '/v1/rentals/byId', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Rentals_List(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/rentals/list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Invoices_List(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/invoices/list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    File_Exist(doctype, callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/file/exist/' + doctype, datas, {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    File_Download(doctype, callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/file/download/' + doctype, datas, {
            responseType: "arraybuffer",
            credentials: false
        }).then(res => { callback(res) }, reserror => { callback(reserror); });
    }

    File_Invoice_Download(number, callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/file/download/invoice/' + number, datas, {
            responseType: "arraybuffer",
            credentials: false
        }).then(res => { callback(res); }, reserror => { callback(reserror); });
    }

    File_Rental_Download(number, callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/file/download/rental/' + number, datas, {
            responseType: "arraybuffer",
            credentials: false
        }).then(res => { callback(res); }, reserror => { callback(reserror); });
    }

    File_Upload(file, doctype, callback) {
        let formData = new FormData();
        formData.append('file', file);
        Vue.http.post(this.URL + '/v1/file/upload/' + doctype, formData, {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => { callback(res.bodyText); }, reserror => { callback(reserror); });
    }

    GetHmac(paquet, callback) {
        var datas = {};
        datas.paquet = paquet;
        Vue.http.post(this.URL + '/v1/hmac', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Payfip_init_renew(data, callback) {
        var datas = {};
        datas.data = data
        Vue.http.post(this.URL + '/v1/payfip/create/renew', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Payfip_init(data, callback) {
        var datas = {};
        datas.data = data
        Vue.http.post(this.URL + '/v1/payfip/create', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Recaptcha_Send(recaptcha_user, callback) {
        var datas = {};
        datas.recaptcha_user = recaptcha_user

        Vue.http.post(this.URL + '/v1/recaptcha', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Reservation_Create(vdata, callback) {
        console.log("Reservation_Create");
        var datas = {};
        datas.source = vdata
        console.log("vdata", datas);
        Vue.http.post(this.URL + '/v1/reservation/create', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Reservation_Cancel(numero, callback) {
        var datas = {};
        datas.numero = numero
        Vue.http.post(this.URL + '/v1/reservation/cancel', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Reservation_Nostock(delivery_place, selected_velo, callback) {
        var datas = { delivery_place, selected_velo };
        console.log("Reservation_Nostock", datas);
        Vue.http.post(this.URL + '/v1/reservation/nostock', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Iban_verification(iban, callback) {
        var datas = { iban }
        Vue.http.post(this.URL + '/v1/iban/verification', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            console.log("Iban_verification res", res);
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

}

export default new DataAPI()