<template>
  <div>
    <div
      class="line showpc"
      style="height: 70px; border-bottom: solid 1px var(--C3)"
    >
      <div class="line-33">
        <ul
          style="
            list-style: none;
            padding: 0px;
            margin: 0px;
            text-align: left;
            margin-left: 15px;
          "
        >
          <li style="line-height: 60px">
            <img
              src="media/logo_societe.jpg"
              alt=""
              height="60px"
            />
              <!-- style="height: 60px; margin-top: 5px" -->
          </li>
        </ul>
      </div>
      <div class="line-34">
        <ul
          style="
            list-style: none;
            padding: 0px;
            margin: 0px;
            text-align: center;
          "
        >
          <li style="line-height: 60px">
            <!-- src="media/logo.png" -->
            <img
              src=""
              @click="home()"
              alt=""
              style="height: 50px; margin-top: 5px; cursor: pointer"
            />
          </li>
        </ul>
      </div>
      <div class="line-33">
        <ul
          style="
            list-style: none;
            padding: 0px;
            margin: 0px;
            text-align: right;
            margin-right: 15px;
          "
        >
          <li style="line-height: 60px">
            <login :account="account"></login>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="line showmobile"
      style="height: 60px; border-bottom: solid 1px var(--C3)"
    >
      <div class="line-50">
        <ul
          style="
            list-style: none;
            padding: 0px;
            margin: 0px;
            text-align: left;
            margin-left: 15px;
          "
        >
          <li style="line-height: 60px">
            <img
              src="media/logo.png"
              alt=""
              style="height: 50px; margin-top: 5px"
            />
          </li>
        </ul>
      </div>
      <div class="line-50">
        <ul
          style="
            list-style: none;
            padding: 0px;
            margin: 0px;
            text-align: right;
            margin-right: 15px;
          "
        >
          <li style="line-height: 60px">
            <login :account="account"></login>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import login from "@/components/IconLogin";

export default {
  methods: {
    home: function () {
      var tmain = this;
      tmain.$router.push("/board");
    },
  },
  components: {
    login,
  },
  props: {
    account: {},
  },
};
</script>

<style>
</style>